export const getVideoURL = (asset) => {
  let allPresets = asset?.presets?.filter((x) => x?.presetId * 1 !== 0); // MATER HAS PRESETID 0
  // determine proper preset url, 512, 640, 768, 960, 1024, 1280, 1920 and lower bitrate (the X kbps value)
  let lowestWidth = Number.MAX_SAFE_INTEGER;
  let lowestBitrate = Number.MAX_SAFE_INTEGER;
  let lowestPresetUrl;

  if (!allPresets) {
    allPresets = [];
  }

  if (allPresets?.length === 1 && allPresets?.[0]?.dlUrl) {
    // If there's only one preset, use it directly
    return allPresets[0]?.dlUrl;
  }

  for (const preset of allPresets) {
    const matches = /\b(\d+)x\d+\b.*?(\d+kbps)/.exec(preset.name);

    if (matches !== null) {
      const width = parseInt(matches[1], 10);
      const bitrate = parseInt(matches[2], 10);

      if (width < lowestWidth || (width === lowestWidth && bitrate < lowestBitrate)) {
        lowestWidth = width;
        lowestBitrate = bitrate;
        lowestPresetUrl = preset.dlUrl;
      }
    }
  }

  if (lowestPresetUrl) {
    return lowestPresetUrl;
  } else {
    // THERE ARE NOO PRESETS AND WE NEED TO CHECK FOR MASTER AND NOTE MASTER HAS TO BE MP4

    // CHECK FOR FALLBACK PRESETS->
    // so when we cannot make any "real" presets, we just add the master as a fallback "preset"
    // when the source file does not have enough quality that matches any available presets that could be made

    // WE ARE CHECKING THIS CAUSE THIS FALLBACK PRESET WILL BE MP4 AND ORIGINAL MASTER FILE IS NOT NECESSARY MP4

    // usman comments ->convert .name to lowercase and check if contains fallback

    const fallback_prest = asset?.presets?.filter((x) => x?.name?.toLowerCase()?.includes('fallback')); // this means it has some fallback

    if (fallback_prest?.length > 0) {
      return fallback_prest?.[0]?.dlUrl;
    }

    const master_preset = asset?.presets?.filter((x) => x?.presetId * 1 === 0);

    if (master_preset?.includes('.mp4')) {
      // MASTER IS MOP4
      return master_preset?.dlUrl;
    } else {
      if (allPresets[0]?.dlUrl) {
        return allPresets[0]?.dlUrl;
      } else {
        return null;
      }
    }
  }
};
